import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Divider, Link, Typography } from '@mui/material';
import NextLink from 'next/link';
import LoginForm from '@components/core/AuthenticationLoginForm/AuthenticationLoginForm';
import { getSession, signOut } from 'next-auth/react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import routes from 'routes';
import { Logo } from '@components/design-system';
import { Alert, Box } from '@components-shared/design-system';
import { Role } from 'lib/auth/legacy-jwt';
import useFeatureFlags from 'hooks/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  container: {
    padding: theme.spacing(0),

    backgroundColor: theme.palette.background.paper,
    margi: 0,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      maxWidth: '444px',
      padding: theme.spacing(4, 2),

      borderRadius: theme.spacing(2),
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      boxShadow: 'rgb(0 0 0 / 12%) 0px 6px 16px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontWeight: 'bold',
  },

  divider: {
    margin: theme.spacing(2, 0),
  },
  loginLink: {
    fontWeight: 500,
    textDecoration: 'underline',
  },

  padding: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0),
    },
  },
}));

export default function LoginPage({ referrerUrl }: { referrerUrl: string }) {
  const classes = useStyles();
  const router = useRouter();
  const redirectUri = router.query.redirect_uri?.toString() || '/';
  const errorMessage = router.query.errorMessage?.toString() || null;
  const { signupDisabled } = useFeatureFlags();

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Box marginBottom={2}>
          <Alert severity="error">{decodeURIComponent(errorMessage)}</Alert>
        </Box>
      )}
      <Head>
        <title>Login - Yottled</title>
      </Head>
      <Container className={classes.container}>
        <div className={classes.header}>
          <Logo size="lg" href="https://yottled.com" />
        </div>

        <Divider className={classes.divider} />

        <div className={classes.padding}>
          <LoginForm redirectUri={redirectUri} referrerUrl={referrerUrl} />
        </div>

        {!signupDisabled && (
          <>
            <Divider className={classes.divider} />
            <Typography className={classes.padding} variant="body2" gutterBottom={true}>
              Don&apos;t have an account?{' '}
              <NextLink passHref href={routes.internal.signup.href()}>
                <Link className={classes.loginLink} underline="hover">
                  Sign up
                </Link>
              </NextLink>
            </Typography>
          </>
        )}
      </Container>

      <Typography className={classes.padding} variant="caption" color="textSecondary">
        Having trouble? Email us at{' '}
        <Link href={`mailto:hello@yottled.com`} underline="hover">
          hello@yottled.com
        </Link>
      </Typography>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession(ctx);
  const redirectUri = ctx.query.redirect_uri?.toString() || '/';

  if (session) {
    if (session.user?.role === Role.CLIENT) {
      return {
        redirect: {
          destination: routes.internal.client.href(),
          permanent: false,
        },
      };
    }

    if (session.user?.workspaceUuid) {
      return {
        redirect: {
          destination: redirectUri,
          permanent: false,
        },
      };
    }

    if (typeof window !== 'undefined') {
      // Invalid token state. Force re-auth.
      await signOut();
    }
  }

  return {
    props: {
      referrerUrl: process.env.NEXTAUTH_URL,
    },
  };
};
