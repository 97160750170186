import { format } from 'url';
import { buildAbsoluteUrl } from 'lib/navigation/absolute-url';
import btoa from 'btoa';
import { CalendarEventsTableDirection, CalendarView } from '@components/pages/CalendarIndexPage';
import { EventShowPageTab } from '@components/pages/EventShowPage';

const buildRoute = (
  path: string,
  queryParams?: Record<string, string | string[] | number | boolean | undefined>,
): string => {
  if (queryParams) {
    // Remove 'undefined' query params
    Object.keys(queryParams).forEach(
      (key) =>
        (queryParams[key] === undefined || queryParams[key] === 'undefined') &&
        delete queryParams[key],
    );
  }

  return format({
    pathname: path,
    query: queryParams,
  });
};

const routes = {
  internal: {
    notFound: {
      href: () => buildRoute('/404'),
    },
    onboard: {
      href: () => buildRoute('/onboard'),
    },
    admin: {
      href: () => buildRoute('/admin'),

      users: {
        href: () => buildRoute('/admin/users'),
      },

      sales: {
        href: () => buildRoute('/admin/sales'),
      },

      migrations: {
        href: () => buildRoute('/admin/migrations'),
      },

      workspace: {
        delete: {
          href: () => buildRoute('/admin/workspace/delete'),
        },
        import: {
          vagaro: {
            href: () => buildRoute('/admin/workspace/import/vagaro'),
          },
        },
      },
    },

    giftCards: {
      show: {
        href: (uuid: string) => buildRoute(`/gift-cards/${uuid}`),
      },
    },

    creditPacks: {
      show: {
        href: (uuid: string) => buildRoute(`/credit-packs/${uuid}`),
      },
    },

    products: {
      href: (uuid: string) => buildRoute(`/products/${uuid}`),
    },

    booking: {
      show: {
        href: (attendeeUuid: string) => buildRoute(`/booking/${attendeeUuid}`),
      },
    },

    selectWorkspace: {
      href: () => buildRoute('/select-workspace'),
    },
    email: {
      unsubscribe: {
        href: (crmClientUuid: string) => buildRoute(`/email/unsubscribe/${crmClientUuid}`),
      },
    },
    workspaces: {
      ical: {
        href: ({
          workspaceUuid,
          startDatetime,
          endDatetime,
        }: {
          workspaceUuid: string;
          startDatetime?: string;
          endDatetime?: string;
        }) =>
          buildAbsoluteUrl(
            buildRoute(`/workspaces/${workspaceUuid}/ical`, {
              start_datetime: startDatetime,
              end_datetime: endDatetime,
            }),
          ),
      },
      signup: {
        href: ({ workspaceUuid, state }: { workspaceUuid: string; state?: string | string[] }) =>
          buildRoute(`/workspaces/${workspaceUuid}/signup`, { state }),
      },
    },

    login: {
      href: ({ errorMessage }: { errorMessage?: string } = {}) =>
        buildRoute(`/login`, { errorMessage }),
    },

    slug: {
      login: {
        href: ({ slug }: { slug: string }) => buildRoute(`/${slug}/login`),
      },
      signup: {
        href: ({ slug }: { slug: string }) => buildRoute(`/${slug}/signup`),
      },
    },
    client: {
      href: () => buildRoute(`/client`),
      membership: {
        show: {
          href: (uuid: string) => buildRoute(`/client/memberships/${uuid}`),
        },
      },
      hostAccount: {
        href: () => buildRoute(`/client/host-account`),
      },
    },

    orders: {
      show: {
        href: (orderUuid: string) => buildRoute(`/orders/${orderUuid}`),
      },
      refund: {
        href: (orderUuid: string) => buildRoute(`/orders/${orderUuid}/refund`),
      },
    },

    signup: {
      href: (params?: { redirectUri?: string; referralCode?: string; email?: string }) =>
        buildRoute('/signup', params),

      success: {
        href: (params?: { state?: string }) => buildRoute(`/signup/success`, params),
      },
    },
    home: {
      href: (params?: { survey?: boolean }) => buildRoute('/', { survey: params?.survey }),
    },

    dashboard: {
      href: (params?: { survey?: boolean }) => buildRoute('/dashboard', { survey: params?.survey }),
    },

    embed: {
      appointments: {
        show: {
          href: (hostSlug: string, appointmentOfferingSlug: string) =>
            buildRoute(`/embed/${hostSlug}/appointments/${appointmentOfferingSlug}`),
        },
      },
      events: {
        href: (hostSlug: string) => buildRoute(`/embed/${hostSlug}/events`),
      },
      forms: {
        href: (hostSlug: string, formUuid: string) =>
          buildRoute(`/embed/${hostSlug}/forms/${formUuid}`),
      },
      products: {
        href: (hostSlug: string) => buildRoute(`/embed/${hostSlug}/products`),
      },
    },
    profiles: {
      show: {
        href: (profileSlug: string) => buildRoute(`/${profileSlug}`),
      },
      orders: {
        pay: {
          href: (profileSlug: string, orderUuid: string) =>
            buildRoute(`/${profileSlug}/orders/${orderUuid}/pay`),
        },
        thankYou: {
          href: (profileSlug: string, orderUuid: string) =>
            buildRoute(`/${profileSlug}/orders/${orderUuid}/thank_you`),
        },
      },
      memberships: {
        href: ({ workspaceSlug }: { workspaceSlug: string }) =>
          buildRoute(`/${workspaceSlug}/memberships`),
        show: {
          href: ({ slug, uuid }: { slug: string; uuid: string }) =>
            buildRoute(`/${slug}/memberships/${uuid}`),
        },
      },
      giftCards: {
        href: (slug: string) => buildRoute(`/${slug}/gift-cards`),
      },
      creditPacks: {
        href: (slug: string) => buildRoute(`/${slug}/credit-packs`),
      },
      checkout: {
        href: ({ workspaceSlug, checkoutUuid }: { workspaceSlug: string; checkoutUuid: string }) =>
          buildRoute(`/${workspaceSlug}/checkout/${checkoutUuid}`),
        lineItem: {
          fulfill: {
            href: ({
              workspaceSlug,
              checkoutLineItemUuid,
            }: {
              workspaceSlug: string;
              checkoutLineItemUuid: string;
            }) =>
              buildRoute(`/${workspaceSlug}/checkout-line-item/${checkoutLineItemUuid}/fulfill`),
          },
        },
        complete: {
          href: ({
            workspaceSlug,
            checkoutUuid,
          }: {
            workspaceSlug: string;
            checkoutUuid: string;
          }) => buildRoute(`/${workspaceSlug}/checkout/${checkoutUuid}/complete`),
        },
        membershipPlan: {
          href: ({
            workspaceSlug,
            membeshipPlanUuid,
          }: {
            workspaceSlug: string;
            membeshipPlanUuid: string;
          }) => buildRoute(`/${workspaceSlug}/checkout/memberships/${membeshipPlanUuid}`),
        },
      },
      events: {
        show: {
          href: ({ slug, uuid }: { slug: string; uuid: string }) =>
            buildRoute(`/${slug}/events/e/${uuid}`),
        },
        all: {
          href: ({ slug }: { slug: string }) => buildRoute(`/${slug}/events`),
        },
      },
      appointments: {
        all: {
          href: (userSlug: string) => buildRoute(`/${userSlug}/appointments`),
        },
        show: {
          href: (params: {
            workspaceSlug: string;
            offeringSlug: string;
            initialEmail?: string | string[];
          }) =>
            buildRoute(`/${params.workspaceSlug}/appointments/${params.offeringSlug}`, {
              email: params.initialEmail,
            }),
          variant: {
            href: (params: {
              workspaceSlug: string;
              appointmentOfferingSlug: string;
              productVariantUuid: string;
              initialEmail?: string | string[];
              month?: string | string[];
              date?: string | string[];
            }) =>
              buildRoute(
                `/${params.workspaceSlug}/appointments/${params.appointmentOfferingSlug}/${params.productVariantUuid}`,
                {
                  email: params.initialEmail,
                  month: params.month,
                  date: params.date,
                },
              ),
          },
        },
      },
      content: {
        href: (hostSlug: string) => buildRoute(`/${hostSlug}/content`),
        show: {
          href: (hostSlug: string, productSlug: string) =>
            buildRoute(`/${hostSlug}/content/${productSlug}`),

          membershipPlans: {
            href: ({ slug, productUuid }: { slug: string; productUuid: string }) =>
              buildRoute(`/${slug}/content/${productUuid}/membership-plans`),
          },
        },
      },
      products: {
        show: {
          href: (hostSlug: string, productSlug: string) =>
            buildRoute(`/${hostSlug}/products/${productSlug}`),
        },
      },
      collections: {
        show: {
          href: (hostSlug: string, collectionUuid: string) =>
            buildRoute(`/${hostSlug}/collections/${collectionUuid}`),
        },
      },
      catalog: {
          categories: {
              show: {
                  href: (hostSlug: string, catalogCategoryUuid: string) =>
                    buildRoute(`/${hostSlug}/catalog/categories/${catalogCategoryUuid}`)
              }
          }
      }
    },

    docs: {
      widgets: {
        events: {
          upcoming: {
            href: ({ hostSlug }: { hostSlug: string }) =>
              buildRoute(`/docs/widgets/events/upcoming`, {
                hostSlug,
              }),
          },
        },

        products: {
          all: {
            href: ({ hostSlug }: { hostSlug: string }) =>
              buildRoute(`/docs/widgets/products/all`, {
                hostSlug,
              }),
          },
        },

        intakeForms: {
          iframe: {
            href: ({ hostSlug }: { hostSlug: string }) =>
              buildRoute(`/docs/widgets/intake-forms/iframe`, {
                hostSlug,
              }),
          },
        },
      },
    },

    viewer: {
      catalog: {
        href: () => buildRoute('/me/catalog'),
        show: {
          href: (uuid: string) => buildRoute(`/me/catalog/${uuid}`),
        },
        services: {
          create: {
            href: () => buildRoute(`/me/catalog/services/create`),
          },
        },

        products: {
          create: {
            href: () => buildRoute(`/me/catalog/products/create`),
          },
        },

        variants: {
          new: {
            href: (params: { productUuid: string }) =>
              buildRoute(`/me/catalog/${params.productUuid}/variants/new`),
          },
          show: {
            href: (params: { productUuid: string; variantUuid: string }) =>
              buildRoute(`/me/catalog/${params.productUuid}/variants/${params.variantUuid}`),
          },
        },

        giftCards: {
          create: {
            href: () => buildRoute(`/me/catalog/gift-cards/create`),
          },
        },

        creditPacks: {
          create: {
            href: () => buildRoute(`/me/catalog/credit-packs/create`),
          },
        },

        categories: {
          href: () => buildRoute('/me/catalog/categories'),
          show: {
            href: (catalogCategoryUuid: string) => buildRoute(`/me/catalog/categories/${catalogCategoryUuid}`),
          },
          create: {
            href: () => buildRoute(`/me/catalog/categories/create`),
          },
          edit: {
              details: {
                href: (catalogCategoryUuid: string) => buildRoute(`/me/catalog/categories/${catalogCategoryUuid}/details/edit`),
              },
              members: {
                href: (catalogCategoryUuid: string) => buildRoute(`/me/catalog/categories/${catalogCategoryUuid}/members/edit`),
              },
          },
        },
      },
      media: {
        href: () => buildRoute('/me/media'),

        show: {
          href: (mediaUuid: string) => buildRoute(`/me/media/${mediaUuid}`),
        },
      },
      widgets: {
        href: (config?: {
          tab?: 'events' | 'appointment_offerings' | 'products' | 'intake_forms';
        }) => buildRoute('/me/widgets', { tab: config?.tab }),
      },
      content: {
        href: () => buildRoute('/me/content'),

        create: {
          href: () => buildRoute('/me/content/create'),
        },

        show: {
          href: (uuid: string) => buildRoute(`/me/content/${uuid}`),
        },
      },

      intakeForms: {
        href: () => buildRoute('/me/intake-forms'),
      },

      memberships: {
        href: () => buildRoute('/me/memberships'),

        create: {
          href: () => buildRoute('/me/memberships/create'),
        },

        show: {
          href: (membershipPlanUuid: string, tab?: 'edit') =>
            buildRoute(`/me/memberships/${membershipPlanUuid}`, { tab }),
        },

        // TODO(willbrazil): Update to `/me/memberships/create` when we migrate
        // the URL to create membership **plans** from `/me/memberships/create` to `/me/membership-plans/create`.
        instance: {
          show: {
            href: (uuid: string) => buildRoute(`/me/memberships/instance/${uuid}`),
          },

          create: {
            href: () => buildRoute('/me/memberships/instance/create'),
          },
        },
      },
      orders: {
        href: (params: { crmClientUuid?: string } = {}) => buildRoute('/me/orders', params),

        create: {
          href: () => buildRoute('/me/orders/create'),
        },

        show: {
          href: (uuid: string) => buildRoute(`/me/orders/${uuid}`),
        },

        edit: {
          href: (uuid: string) => buildRoute(`/me/orders/${uuid}/edit`),
        },

        refund: {
          href: (uuid: string) => buildRoute(`/me/orders/${uuid}/refund`),
        },
      },

      commissions: {
        href: () => buildRoute('/me/commissions'),

        show: {
          href: (uuid: string) => buildRoute(`/me/commissions/${uuid}`),
        },
      },

      reviews: {
        href: () => buildRoute('/me/reviews'),
      },
      campaigns: {
        href: () => buildRoute('/me/email-marketing/campaigns'),

        create: {
          href: () => buildRoute('/me/email-marketing/campaigns/create'),
        },

        show: {
          href: (campaignUuid: string, tab?: 'edit') =>
            buildRoute(`/me/email-marketing/campaigns/${campaignUuid}`, { tab }),

          customize: {
            href: (campaignUuid: string) =>
              buildRoute(`/me/email-marketing/campaigns/${campaignUuid}/customize`),
          },

          preview: {
            href: (campaignUuid: string) =>
              buildRoute(`/me/email-marketing/campaigns/${campaignUuid}/preview`),
          },
        },

        segments: {
          href: () => buildRoute(`/me/email-marketing/audiences`),

          create: {
            href: () => buildRoute(`/me/email-marketing/audiences/create`),
          },

          show: {
            edit: {
              href: (audienceUuid: string) =>
                buildRoute(`/me/email-marketing/audiences/${audienceUuid}/edit`),
            },
          },
        },

        emailTemplates: {
          href: () => buildRoute('/me/email-marketing/templates'),

          create: {
            href: ({ prevUrl }: { prevUrl?: string } = {}) =>
              buildRoute('/me/email-marketing/templates/create', { prevUrl }),
          },

          customize: {
            href: (emailTemplateUuid: string, prevUrl?: string | string[]) =>
              buildRoute(`/me/email-marketing/templates/${emailTemplateUuid}/customize`, {
                prevUrl,
              }),
          },

          preview: {
            href: (emailTemplateUuid: string, prevUrl?: string) =>
              buildRoute(`/me/email-marketing/templates/${emailTemplateUuid}/preview`, { prevUrl }),
          },
        },
      },

      coupons: {
        href: () => buildRoute('/me/coupons'),

        show: {
          href: (uuid: string) => buildRoute(`/me/coupons/${uuid}`),
        },
        create: {
          href: () => buildRoute(`/me/coupons/create`),
        },
      },
      calendar: {
        href: ({
          view,
          crmClientUuid,
          date,
          direction,
        }: {
          view?: CalendarView;
          crmClientUuid?: string;
          date?: Date;
          direction?: CalendarEventsTableDirection;
        } = {}) =>
          buildRoute('/me/calendar', { view, crmClientUuid, date: date?.toISOString(), direction }),

        booking: {
          href: (
            uuid: string,
            params?: {
              startDatetime?: string;
              endDatetime?: string;
              calendarUuid?: string;
            },
          ) => buildRoute(`/me/calendar/booking/${uuid}`, params),

          create: {
            href: (params?: {
              startDatetime?: string;
              endDatetime?: string;
              calendarUuid?: string;
              crmClientUuid?: string;
            }) => buildRoute('/me/calendar/booking/new', params),
          },
        },

        events: {
          show: {
            href: (uuid: string, { tab }: { tab?: EventShowPageTab } = {}) =>
              buildRoute(`/me/calendar/events/${uuid}`, { tab }),

            attendees: {
              show: {
                reschedule: {
                  href: ({
                    attendeeUuid,
                    eventUuid,
                  }: {
                    attendeeUuid: string;
                    eventUuid: string;
                  }) =>
                    buildRoute(
                      `/me/calendar/events/${eventUuid}/attendees/${attendeeUuid}/reschedule`,
                    ),
                },
              },
            },

            reschedule: {
              href: (
                eventUuid: string,
                params: { startDateTime?: string; endDateTime?: string } = {},
              ) => buildRoute(`/me/calendar/events/${eventUuid}/reschedule`, params),
            },
          },

          create: {
            href: () => buildRoute('/me/calendar', { createEvent: true }),
          },

          cancel: {
            href: (uuid: string) => buildRoute(`/me/calendar/events/${uuid}/cancel`),
          },
        },
      },
      collections: {
        href: () => buildRoute(`/me/collections`),
        show: {
          href: (collectionUuid: string) => buildRoute(`/me/collections/${collectionUuid}`),
        },
      },

      offerings: {
        create: {
          href: () => buildRoute(`/me/offerings/create`),
        },
      },

      settings: {
        href: () => buildRoute('/me/settings'),
        integrations: {
          href: () => buildRoute('/me/settings/integrations'),
          zoom: {
            href: () => buildRoute('/me/settings/integrations/zoom'),
          },
        },
        payments: {
          href: () => buildRoute('/me/settings/payments'),

          edit: {
            href: () => buildRoute('/me/settings/payments/edit'),
          },

          tip: {
            edit: {
              href: () => buildRoute(`/me/settings/payments/tip/edit`),
            },
          },

          terminals: {
            create: {
              href: () => buildRoute(`/me/settings/payments/terminals/create`),
            },
          },

          stripe: {
            disconnect: {
              href: () => buildRoute(`/me/settings/payments/stripe/disconnect`),
            },
          },
        },
        team: {
          href: () => buildRoute('/me/settings/users'),

          invite: {
            href: () => buildRoute('/me/settings/users/invite'),
          },

          show: {
            href: (workspaceUserUuid: string) =>
              buildRoute(`/me/settings/users/${workspaceUserUuid}`),

            calendars: {
              show: {
                href: (workspaceUserUuid: string, calendarUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/calendars/${calendarUuid}`),
              },

              create: {
                href: (workspaceUserUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/calendars/new`),
              },
            },

            commissions: {
              edit: {
                href: (workspaceUserUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/commissions/edit`),
              },
            },

            roles: {
              edit: {
                href: (workspaceUserUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/roles/edit`),
              },
            },

            personalInfo: {
              edit: {
                href: (workspaceUserUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/personal-info/edit`),
              },
            },

            availability: {
              edit: {
                href: (workspaceUserUuid: string) =>
                  buildRoute(`/me/settings/users/${workspaceUserUuid}/availability/edit`),
              }
            }
          },
        },
        roles: {
          href: () => buildRoute('/me/settings/roles'),

          show: {
            href: (roleUuid: string) => buildRoute(`/me/settings/roles/${roleUuid}`),
          },
        },
        general: {
          href: () => buildRoute('/me/settings/general'),
          basicInfo: {
            edit: {
              href: () => buildRoute('/me/settings/general/basic-info/edit'),
            },
          },
          socialLinks: {
            edit: {
              href: () => buildRoute('/me/settings/general/social-links/edit'),
            },
          },
          policy: {
            edit: {
              href: () => buildRoute('/me/settings/general/policy/edit'),
            },
          },
          storefrontAvailability: {
            edit: {
              href: () => buildRoute('/me/settings/general/availability/edit'),
            }
          },
          location: {
            edit: {
              href: () => buildRoute('/me/settings/general/location/edit'),
            }
          },
        },
        workspaces: {
          href: () => buildRoute(`/me/settings/workspaces`),
        },
        locations: {
          href: () => buildRoute(`/me/settings/locations`),

          create: {
            href: () => buildRoute(`/me/settings/locations/create`),
          },

          delete: {
            href: (locationUuid: string) =>
              buildRoute(`/me/settings/locations/${locationUuid}/delete`),
          },

          show: {
            href: (locationUuid: string) => buildRoute(`/me/settings/locations/${locationUuid}`),
          },
        },
        taxes: {
          href: () => buildRoute(`/me/settings/taxes`),

          create: {
            href: () => buildRoute(`/me/settings/taxes/create`),
          },

          edit: {
            href: (taxUuid: string) => buildRoute(`/me/settings/taxes/${taxUuid}/edit`),
          },
        },
        workspaceResources: {
          href: () => buildRoute(`/me/settings/workspace-resources`),
          show: {
            href: (workspaceResourceUuid: string) =>
              buildRoute(`/me/settings/workspace-resources/${workspaceResourceUuid}`),
          },
        },
        metadata: {
          href: () => buildRoute(`/me/settings/metadata`),
        },
        notifications: {
          href: () => buildRoute(`/me/settings/notifications`),
        },
      },
      contacts: {
        href: (params?: {
          upload?: 'individual' | 'bulk';
          eventUuid?: string;
          audienceUuid?: string;
        }) =>
          buildRoute(`/me/contacts`, {
            eventUuid: params?.eventUuid,
            upload: params?.upload,
            audienceUuid: params?.audienceUuid,
          }),
        export: {
          href: () => buildRoute(`/me/contacts/export`),
        },
        show: {
          href: (contactUuid: string) => buildRoute(`/me/contacts`, { customerUuid: contactUuid }),
        },
        create: {
          href: () => buildRoute(`/me/contacts`, { upload: 'individual' }),
        },
        createBulk: {
          href: () => buildRoute(`/me/contacts`, { upload: 'bulk' }),
        },

        formSubmissions: {
          show: {
            href: ({ uuid }: { uuid: string }) => buildRoute(`/me/form-submissions/${uuid}`),
          },
        },
      },
      workspaces: {
        href: () => buildRoute(`/me/workspaces`),
        instance: {
          href: (uuid: string) => buildRoute(`/me/workspaces/${uuid}`),
        },
      },

      giftCards: {
        href: () => buildRoute('/me/gift-cards'),
        show: {
          href: (uuid: string) => buildRoute(`/me/gift-cards/${uuid}`),
        },
        create: {
          href: () => buildRoute(`/me/gift-cards/new`),
        },
      },
      reports: {
        href: () => buildRoute('/me/reports'),
        show: {
          href: (dashboardId: string, dashboardName: string) =>
            buildRoute(`/me/reports/${dashboardId}`, { dashboardName }),
        },
      },
    },

    download: {
      assets: {
        href: (uuid: string) => buildRoute(`/download/assets/${uuid}`),
      },
    },
  },
  external: {
    googleMapsLocation: {
      href: (locationQuery: string) => `https://www.google.com/maps?q=${locationQuery}`,
    },
    emailYottled: {
      href: () => 'mailto:hello@yottled.com',
    },
    marketingSite: {
      href: () => 'https://yottled.com',

      pricing: {
        href: () => 'https://yottled.com/pricing',
      },
    },

    knowledgebase: {
      href: () => 'https://yottledhelp.zendesk.com/hc/en-us',
      cancelationPolicy: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13103100329236-Cancellation-policies',
      },
      appointmentsOverview: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/sections/13042572672276-Appointments-reservations',
      },
      eventsOverview: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13048757734932-Events-Classes',
      },
      editPersonalPage: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13166317730068-How-do-I-customize-my-business-details-',
      },
      paymentsOverview: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/categories/13170442313620-Payments-and-orders',
      },
      stripeSetup: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13101992610836-Connecting-a-credit-card-processor',
      },
      membershipsOverview: {
        href: () => 'https://yottledhelp.zendesk.com/hc/en-us/sections/13042668770068-Memberships',
      },
      integrations: {
        zapier: {
          href: () =>
            'https://yottledhelp.zendesk.com/hc/en-us/articles/13166261503764-How-do-I-use-Zapier-',
        },
      },
      contacts: {
        href: () => 'https://yottledhelp.zendesk.com/hc/en-us/categories/12942498131988-Contacts',
        howToUpload: {
          href: () =>
            'https://yottledhelp.zendesk.com/hc/en-us/articles/13107641571476-Upload-a-contact-list',
        },
      },
      customIntakeForm: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/categories/13108471141396-Intake-forms-',
      },
      pricingOverview: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13040368274324-What-are-the-payment-processing-fees-',
      },
      feesOverview: {
        href: () => 'https://yottledhelp.zendesk.com/hc/en-us/articles/13104125133844-Payment-Fees',
      },
      roles: {
        href: () =>
          'https://yottledhelp.zendesk.com/hc/en-us/articles/13722173370516-New-Roles-available',
        permissions: {
          href: () =>
            'https://yottledhelp.zendesk.com/hc/en-us/articles/13722173370516-New-Roles-available',
        },
      },
      locations: {
        href: () =>
          `https://yottledhelp.zendesk.com/hc/en-us/articles/13356127741716-How-to-set-up-your-location-`,
      },
    },
    stripe: {
      href: () => 'https://www.stripe.com/',
      success: {
        href: () => 'https://dashboard.stripe.com/payments?status%5B%5D=successful',
      },
      purchaseTerminal: {
        href: () => 'https://buy.stripe.com/4gw4jE8ZxaQg6FG7ss',
      },
    },
    chatSupport: {
      href: () => 'https://yottledhelp.zendesk.com/hc/en-us',
    },

    termsOfService: {
      href: () => 'https://yottled.com/terms-of-service/',
    },
    privacyPolicy: {
      href: () => 'https://yottled.com/privacy-policy/',
    },
    primaryWebsite: {
      href: () => 'https://yottled.com',
    },
    memberships: {
      href: () => 'https://yottled.com/memberships/',
    },
    salesBookADemo: {
      href: () =>
        'https://app.yottled.com/trevor/appointments/872/1416353d-5c80-4473-9f37-fc6899b431ec',
    },
  },

  api: {
    ical: {
      workspaces: {
        href: ({
          workspaceUuid,
          startDatetime,
          endDatetime,
        }: {
          workspaceUuid: string;
          startDatetime?: string;
          endDatetime?: string;
        }) =>
          buildRoute(`${process.env.NEXT_PUBLIC_API_ROOT}/ical/v1/workspaces/${workspaceUuid}`, {
            start_datetime: startDatetime,
            end_datetime: endDatetime,
          }),
      },
    },

    email: {
      workspaces: {
        signup: {
          href: ({ workspaceUuid, email }: { workspaceUuid: string; email: string }) =>
            buildAbsoluteUrl(
              buildRoute(`/api/email/workspaces/${workspaceUuid}/signup`, {
                state: btoa(JSON.stringify({ email })),
              }),
            ),
        },
      },

      giftCards: {
        show: {
          href: (uuid: string) => buildAbsoluteUrl(buildRoute(`/api/email/gift-cards/${uuid}`)),
        },
      },
    },

    metaBase: {
      embedReportsUrl: {
        href: (params: { dashboardId: string | number }) =>
          buildRoute(`/api/meta-base/dashboard`, { dashboardId: params.dashboardId }),
      },
    },

    v1: {
      calendars: {
        show: {
          redirect: {
            href: (calendarUuid: string) => `/api/v1/calendars/${calendarUuid}/redirect`,
          },
        },
      },
    },
  },
};

export default routes;
