import React from 'react';
import { signIn } from 'next-auth/react';
import { EmployeeOnly } from '@components-shared/core';
import { Button, makeStyles } from '@components-shared/design-system';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  startIcon: {
    position: 'absolute',
    left: theme.spacing(2),
  },
}));

interface Props {
  getCallbackUri: (signupMethod: string) => string;
  label?: string;
}

export default function AuthenticationSignupPageFormSocialButtons(props: Props) {
  const classes = useStyles();

  return (
    <>
      <EmployeeOnly>
        <Button
          className={classes.button}
          classes={{
            startIcon: classes.startIcon,
          }}
          startIcon={<img className={classes.icon} alt="Zoom" src="/static/zoom_social_icon.svg" />}
          variant="outlined"
          fullWidth={true}
          onClick={() => {
            signIn('zoom', {
              callbackUrl: props.getCallbackUri('zoom'),
            });
          }}
        >
          {props.label || 'Continue with Zoom'}
        </Button>
      </EmployeeOnly>

      <Button
        className={classes.button}
        classes={{
          startIcon: classes.startIcon,
        }}
        startIcon={
          <img className={classes.icon} alt="Google" src="/static/google_social_icon.png" />
        }
        variant="outlined"
        fullWidth={true}
        onClick={() => {
          signIn('google', {
            callbackUrl: props.getCallbackUri('google'),
          });
        }}
      >
        {props.label || 'Continue with Google'}
      </Button>
    </>
  );
}
