import React from 'react';
import { makeStyles } from '@mui/styles';
import { lighten } from '@mui/material';
import useYottledEmployee from 'hooks/useYottledEmployee';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.warning.light, 0.95),
  },
}));

interface Props {
  children: React.ReactNode;
}

export default function EmployeeOnly(props: Props) {
  const classes = useStyles();

  const { isEmployee } = useYottledEmployee();

  if (isEmployee) {
    return <span className={classes.root}>{props.children}</span>;
  }

  return null;
}
