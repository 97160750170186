import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

interface UseYottledEmployeeHook {
  isEmployee: boolean;
  loading: boolean;
}

export default function useYottledEmployee(): UseYottledEmployeeHook {
  const { data: session, status } = useSession();

  const loading = status === 'loading';

  const isEmployee = session?.user?.email?.endsWith('@yottled.com') || false;

  const state = useMemo(() => ({ isEmployee, loading }), [isEmployee, loading]);

  return state;
}
