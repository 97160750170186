import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  Link,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Button } from '@components-shared/design-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import AuthenticationSignupPageFormSocialButtons from '@components/pages/AuthenticationSignupPage/AuthenticationSignupPageFormSocialButtons';
import {
  LoginPageSendOneTimePassword,
  LoginPageSendOneTimePasswordVariables,
} from 'generated/types';
import {
  LoginPageVerifyOneTimePassword,
  LoginPageVerifyOneTimePasswordVariables,
} from 'generated/types';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2, 0),
  },
  headerText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  loginLink: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  email: {
    fontWeight: 500,
  },

  useDifferentEmailLink: {
    marginTop: theme.spacing(4),
  },
  emailEmoji: {
    fontSize: '17pt',
    marginBottom: theme.spacing(2),
  },
  socialButtons: {
    padding: theme.spacing(2, 0),
  },

  orContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  orDivider: {
    flex: 1,
  },
}));

interface Props {
  redirectUri?: string;
  referrerUrl?: string;
  hideSocialLogin?: boolean;
  client?: boolean;
}

export default function AuthenticationLoginForm(props: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const router = useRouter();

  const ref = React.createRef<HTMLFormElement>();

  const [magicCodeVisible, setMagicCodeVisible] = React.useState(false);
  const [sendOneTimePassword, sendOneTimePasswordMutation] = useMutation<
    LoginPageSendOneTimePassword,
    LoginPageSendOneTimePasswordVariables
  >(MUTATION_SEND_ONE_TIME_PASSWORD, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setMagicCodeVisible(true);
    },
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: '',
      client: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required().email('Please enter a valid email'),
      client: Yup.bool().nullable().optional().label('Client token'),
    }),
    onSubmit: async (values) => {
      sendOneTimePassword({
        variables: {
          email: values.email.trim().toLocaleLowerCase(),
        },
      });
    },
  });

  const [verifyOneTimePassword, verifyOneTimePasswordMutation] = useMutation<
    LoginPageVerifyOneTimePassword,
    LoginPageVerifyOneTimePasswordVariables
  >(MUTATION_VERIFY_ONE_TIME_PASSWORD, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.verifyOneTimePassword) {
        router.push(data.verifyOneTimePassword.url);
      }
    },
  });

  const verifyOneTimePasswordForm = useFormik({
    validateOnChange: false,
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().min(6).max(6).required(),
    }),
    onSubmit: async (values) => {
      verifyOneTimePassword({
        variables: {
          input: {
            email: formik.values.email,
            code: values.code,
            redirectUrl: props.redirectUri,
            client: formik.values.client,
            referrerUrl: props.referrerUrl,
          },
        },
      });
    },
  });

  const onUseDifferentEmailClick = () => {
    setMagicCodeVisible(false);
    formik.resetForm();
  };

  if (magicCodeVisible && !sendOneTimePasswordMutation.error) {
    return (
      <div>
        <Fade in={magicCodeVisible} timeout={1000}>
          <div className={classes.confirmationContainer}>
            <span className={classes.emailEmoji} role="img" aria-label="Email">
              💌
            </span>

            <Typography variant="body1">
              We&apos;ve sent a code to{' '}
              <Box className={classes.email} component="span">
                {formik.values.email}
              </Box>
              .<br />
              Please enter the code to log in.
            </Typography>

            <form
              className={classes.form}
              onSubmit={verifyOneTimePasswordForm.handleSubmit}
              ref={ref}
            >
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  id="code"
                  name="code"
                  label="Magic Code"
                  placeholder="Magic Code"
                  variant="outlined"
                  fullWidth={true}
                  value={verifyOneTimePasswordForm.values.code}
                  onChange={verifyOneTimePasswordForm.handleChange}
                  error={Boolean(verifyOneTimePasswordForm.errors.code)}
                />
                <FormHelperText error={true}>
                  {verifyOneTimePasswordForm.errors.code}
                </FormHelperText>
              </FormControl>

              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="secondary"
                loading={verifyOneTimePasswordMutation.loading}
              >
                Log me in
              </Button>
            </form>

            <Link
              className={classes.useDifferentEmailLink}
              onClick={onUseDifferentEmailClick}
              underline="hover"
            >
              <Typography variant="body2" color="textSecondary">
                Try a different email
              </Typography>
            </Link>
          </div>
        </Fade>
      </div>
    );
  }

  const error =
    formik.errors.email ||
    sendOneTimePasswordMutation.error?.graphQLErrors.map((e) => e.message).join(',');

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit} ref={ref}>
        <input hidden readOnly id="client" name="client" />
        <Typography className={classes.headerText} variant="subtitle1" align="left">
          Login
        </Typography>

        <FormControl variant="standard" className={classes.formControl}>
          <TextField
            id="email"
            name="email"
            label="Email"
            placeholder="Email"
            variant="outlined"
            fullWidth={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(error)}
          />
          <FormHelperText error={true}>{error}</FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="secondary"
          loading={sendOneTimePasswordMutation.loading}
        >
          Send me a magic code
        </Button>
      </form>

      {!props.hideSocialLogin && (
        <>
          <div className={classes.orContainer}>
            <Divider className={classes.orDivider} />
            <Typography
              style={{ padding: theme.spacing(1) }}
              variant="caption"
              color="textSecondary"
            >
              or
            </Typography>
            <Divider className={classes.orDivider} />
          </div>

          <div className={classes.socialButtons}>
            <AuthenticationSignupPageFormSocialButtons
              getCallbackUri={() => props.redirectUri || '/'}
            />
          </div>
        </>
      )}
    </div>
  );
}

const MUTATION_SEND_ONE_TIME_PASSWORD = gql`
  mutation LoginPageSendOneTimePassword($email: String!) {
    sendOneTimePassword(email: $email)
  }
`;

const MUTATION_VERIFY_ONE_TIME_PASSWORD = gql`
  mutation LoginPageVerifyOneTimePassword($input: VerifyOneTimePasswordInput!) {
    verifyOneTimePassword(input: $input) {
      url
    }
  }
`;
