import { gql, useQuery } from '@apollo/client';
import { UseFeatureFlagsHookGetFlags, UseFeatureFlagsHookGetFlagsVariables } from 'generated/types';
import React from 'react';

export type FeatureFlags = Omit<UseFeatureFlagsHookGetFlags['flags'], '__typename' | 'id'> & {
  loading: boolean;
};

interface Props {
  // Provide the workspace slug when fetching flags for a business's storefront
  workspaceSlug?: string;
}

export default function useFeatureFlags({ workspaceSlug }: Props = {}): FeatureFlags {
  const { data, loading } = useQuery<
    UseFeatureFlagsHookGetFlags,
    UseFeatureFlagsHookGetFlagsVariables
  >(QUERY, {
    variables: { workspaceSlug },
  });

  const state = React.useMemo<FeatureFlags>(
    () => ({
      loading,
      ...(data?.flags || {
        showPaymentTerminal: false,
        showMediaAssetUploader: false,
        adminUserImpersonationEnabled: false,
        adminWorkspaceDeleteEnabled: false,
        authorizationEnabled: false,
        enableOrderPaymentStatusFilter: false,
        enableUserCommissions: false,
        enableCustomerEventReminderConfigurationDialog: false,
        publicProductV2PageEnabled: false,
        refundsEnabled: false,
        addToCartWizardV2Enabled: false,
        membershipWidgetEnabled: false,
        creditPackEnabled: false,
        signupDisabled: false,
      }),
    }),
    [loading, data],
  );

  return state;
}

const QUERY = gql`
  query UseFeatureFlagsHookGetFlags($workspaceSlug: String) {
    flags(workspaceSlug: $workspaceSlug) {
      id
      showPaymentTerminal
      showMediaAssetUploader
      authorizationEnabled
      adminUserImpersonationEnabled
      adminWorkspaceDeleteEnabled
      enableOrderPaymentStatusFilter
      enableUserCommissions
      enableCustomerEventReminderConfigurationDialog
      publicProductV2PageEnabled
      addToCartWizardV2Enabled
      membershipWidgetEnabled
      creditPackEnabled
      signupDisabled
    }
  }
`;
