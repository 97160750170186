import * as React from 'react';
import { LoadingButton, LoadingButtonProps, LoadingButtonTypeMap } from '@mui/lab';

export type ButtonProps<
  C extends React.ElementType<any> = LoadingButtonTypeMap['defaultComponent'],
> = LoadingButtonProps<C, { component?: C }> & {
  testId?: string;
};

const Button: React.ForwardRefExoticComponent<ButtonProps<React.ElementType>> = React.forwardRef(
  (props, ref) => {
    const { loading, component = 'button', testId, ...rest } = props;

    return (
      <LoadingButton
        {...rest}
        disableElevation
        loading={props.loading}
        ref={ref}
        component={component}
        disabled={props.disabled || loading}
        className={props.className}
        data-testid={testId}
      >
        {props.children}
      </LoadingButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
