import * as React from 'react';
import classnames from 'classnames';
import clickable from 'lib/default-styles/clickable';
import { makeStyles } from '@components-shared/design-system';

const useStyles = makeStyles((theme) => ({
  root: clickable(theme),
  sm: {
    height: '15px',
  },
  md: {
    height: '20px',
  },
  lg: {
    height: '30px',
  },
  xl: {
    height: '50px',
  },
}));

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  href?: string;
}

export default function Logo(props: Props) {
  const classes = useStyles();
  return (
    <a href={props.href || process.env.NEXT_PUBLIC_HOSTNAME}>
      <img
        className={classnames(classes.root, {
          [classes.sm]: props.size === 'sm',
          [classes.md]: props.size === 'md' || !props.size, // Default size.
          [classes.lg]: props.size === 'lg',
          [classes.xl]: props.size === 'xl',
        })}
        src="/static/gray_logo.svg"
        alt="logo"
      />
    </a>
  );
}
