import { Theme } from '@mui/material';

const clickable = (theme: Theme) => ({
  transition: theme.transitions.create(['filter'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    filter: 'brightness(0.95)',
    cursor: 'pointer',
  },
});

export default clickable;
